import client from "@/api";
import store from "@/store";
import { _rotasAdmin } from "@/api/_caminhosApi";

export function listarCompanies(orders = false)
{
  store.commit("interacoes/SET_LOADING", true);
  store.commit("admin/RESET_COMPANIES", null);
  let url = (orders === true) ? `${_rotasAdmin()}/companies/mensal-orders` : `${_rotasAdmin()}/companies`;
  client
    .get(url)
    .then(resp => {
      if (resp.status === 200) {
        store.commit("admin/SET_COMPANIES", resp.data.data);
      }
    })
    .catch(erro => {
      console.log(erro);
    })
    .then(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}


export function listarUsers()
{
  store.commit("interacoes/SET_LOADING", true);
  store.commit("admin/RESET_USERS", null);
  client
    .get(`${_rotasAdmin()}/users`)
    .then(resp => {
      if (resp.status === 200) {
        store.commit("admin/SET_USERS", resp.data.data);
      }
    })
    .catch(erro => {
      console.log(erro);
    })
    .then(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}


export function listarInvites()
{
  store.commit("interacoes/SET_LOADING", true);
  store.commit("admin/RESET_INVITES", null);
  client
    .get(`${_rotasAdmin()}/invites`)
    .then(resp => {
      if (resp.status === 200) {
        store.commit("admin/SET_INVITES", resp.data.data);
      }
    })
    .catch(erro => {
      console.log(erro);
    })
    .then(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}


export function listarVtexAccounts()
{
  store.commit("interacoes/SET_LOADING", true);
  store.commit("admin/RESET_VTEX_ACCOUNTS", null);
  client
    .get(`${_rotasAdmin()}/vtex-accounts`)
    .then(resp => {
      if (resp.status === 200) {
        store.commit("admin/SET_VTEX_ACCOUNTS", resp.data.data);
      }
    })
    .catch(erro => {
      console.log(erro);
    })
    .then(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}
