<template>
  <div class="panel">
    <div v-for="box in panel" :key="box.topic">
      <router-link class="box" :to="box.url" v-if="box.role" :class="data == box.topic ? 'active' : ''" active-class="">
        {{ box.topic }}
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: ["data"],
  filters: {},
  methods: {},
  computed: {
    perfilUsuario() {
      return this.$store.state.user.dadosCompletos;
    },
    managerIntegration() {
      return this.$store.state.user.gerenciarIntegracao;
    },
    managerUser() {
      return this.$store.state.user.gerenciarUsuario;
    },
    panel() {
      let items = [
        {
          topic: "Empresas",
          url: "/admin/companies",
          role: true
        },
        {
          topic: "Consumo",
          url: "/admin/companies/orders",
          role: true
        },
        {
          topic: "Usuários",
          url: "/admin/users",
          role: true
        },
        {
          topic: "Invites",
          url: "/admin/invites",
          role: true
        },
        {
          topic: "VTEX Accounts",
          url: "/admin/vtex-accounts",
          role: true
        }
      ];
      return items;
    },
  },
  watch: {
    perfilUsuario(data) {
      return (data.role !== 2) ? this.$router.push({ path: "home" }) : null;
    }
  }
};
</script>

<style lang="scss" scoped>
.router-link-active {
  background: red;
  background-color: #747474;
  color: #e6e6e6;
}
.panel {
  display: inline-block;
  align-items: center;
  margin-right: 45px;
  min-width: 325px;
  max-width: 325px;
  .box {
    border-bottom: 1px solid #ececec;
    align-items: center;
    display: flex;
    justify-content: space-between;
    height: 84px;
    background-color: #fff;
    margin: 0;
    font-size: 20px;
    font-family: $font__main;
    font-weight: bold;
    color: #747474;
    padding-left: 28px;
    padding-right: 28px;
    transition: all 350ms ease-in-out;
    width: 100%;
    white-space: nowrap;
    &:hover,
    &.active {
      background-color: #747474;
      color: #e6e6e6;

      &::after {
        background-color: #e6e6e6;
      }
    }

    &::after {
      height: 21px;
      width: 21px;
      content: "";
      display: block;
      background-color: #747474;
      -webkit-mask-image: url("../../../public/img/icons/icon.svg");
      mask-image: url("../../../public/img/icons/icon.svg");
      -webkit-mask-size: contain;
      -webkit-mask-repeat: no-repeat;
      position: relative;
      margin-left: 15px;
    }
  }
}

@media only screen and (max-width: 997px) {
  .panel {
    display: none;
  }
}
</style>
